<template>
  <div>
    <div v-if="!faDaDaSrc">
      <div class="title">企业授权 <span style="float:right;margin-bottom:10px">
          <el-button size="mini" type="primary" v-if="form.corpIdentStatus === 'unidentified' || !form.corpIdentStatus"
            @click="immediatelyAuthoriize()">立即授权</el-button></span></div>
      <div class="form">
        <el-descriptions class="form-info" title="" :column="1" :colon="true" label-class-name="label-cls">
          <el-descriptions-item label="认证状态">
            <div class="authoriize">
              <el-button type="text" :class="{ 'have': form.corpIdentStatus == 'identified' }">{{ form.corpIdentStatus ===
                "identified" ?
                "已授权" : "未授权" }}</el-button>
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="企业名称">
            {{ form.corpName ? form.corpName : "无" }}
          </el-descriptions-item>
          <el-descriptions-item label="社会统一信用码">
            {{ form.corpIdentNo ? form.corpIdentNo : "无" }}
          </el-descriptions-item>
          <el-descriptions-item label="实名认证方式">
            {{ form.corpIdentMethodName ? form.corpIdentMethodName : "无" }}
          </el-descriptions-item>
          <el-descriptions-item label="法定代表人">
            {{ form.legalRepName ? form.legalRepName : "无" }}
          </el-descriptions-item>
          <el-descriptions-item label="对公银行账户">
            {{ form.bankAccountNo ? form.bankAccountNo : "无" }}
          </el-descriptions-item>
          <el-descriptions-item label="开户行名称">
            {{ form.bankAccountName ? form.bankAccountName : "无" }}
          </el-descriptions-item>
          <el-descriptions-item label="认证通过时间">
            {{ form.identSuccessTime ? form.identSuccessTime : "无" }}
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
    <div v-else>
      <div id="fadadaIframe" v-if="form.corpIdentStatus === 'unidentified' || !form.corpIdentStatus"
        style="height:100%;margin:-20px">
        <el-button class="back-btn" size="mini" @click="backToInfo" plain>返回</el-button>
        <iframe :src="faDaDaSrc" style="height:100%; width:100%;margin:0;border:0;"> </iframe>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {

    return {
      form: {},
      unClickBtn: true,
      faDaDaSrc: null,
      systemIp: window.location.origin + '/?status=success'

    }
  },
  created() {
    this.getInfo() // 获取企业授权
  },
  computed: {
  },
  mounted() {
    this.setMyIframeHeight();
    window.addEventListener("resize", () => this.setMyIframeHeight(), false);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.setMyIframeHeight(), false);
  },

  watch: {
    faDaDaSrc(newVal, oldVal) {
      console.log(newVal)
      console.log(oldVal)
    }
  },
  methods: {
    getInfo() {
      console.log("window.location", window.location)
      let params = {
        process_id: '00000095', // 流程ID ---95是查询是否授权
        nodeInfo: {} // 传参  查询则是查询条件  可传可不传等
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
        .then(result => {
          const { data } = result
          this.form = data
          this.$set(this.form, "identSuccessTime", this.formatDateTime(parseInt(this.form.identSuccessTime)));
        })
        .catch(err => { })
    },
    immediatelyAuthoriize() {
      let params = {
        process_id: '00000094', // 流程ID ---94是获取授权url
        nodeInfo: {} // 传参  查询则是查询条件  可传可不传等
      }
      this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
        .then(result => {
          const { succeed, data, errorMsg } = result
          if (succeed === 0) {

            this.unClickBtn = false
            if (data?.authUrl) {
              this.faDaDaSrc = data.authUrl;
              this.setMyIframeHeight();
            }
          } else {
            this.$message.error(errorMsg)
          }

        })
        .catch(err => { })
    },
    onSubmit(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          let params = {
            process_id: '00000203',
            execution_type: 'set', // get 代表读请求 获取数据,  set	代表写请求 编辑等操作
            nextProcessNode: this.submitBtnId, // 上面接口的  button.NodeId_1字段的值
            nodeInfo: {
              userId: '' /* 用户id（不传或传空，表示更新当前用户的密码） */,
              oldPassword: this.form.opwd /* 旧密码 */,
              newPassword: this.form.npwd /* 新密码 */,
              mobilePhoneVerificationCode: this.form.code /* 手机验证码 */
            }
          }
          this.$post('a6c611cc-ded1-4a49-8823-0c66f6ebf7d9', params)
            .then(res => {
              if (res.succeed == 0) {
                this.$message({
                  message: res.errorMsg,
                  type: 'success'
                })
                // 清空form表单
                this.$router.push('/userInfo/userCenter')
              }
            })
            .catch(err => { })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    clearForm() {
    },
    setMyIframeHeight() {
      // 页面可视高-顶部实际高-底部实际高-多余部分高度
      let that = this;
      this.$nextTick(() => {
        let headerHeight = 60;
        let footerHeight = 0;
        let bodyHeight = document.body.clientHeight;
        let outHeight = parseFloat(bodyHeight - headerHeight - footerHeight) - 5 + "px";
        document.querySelector("#fadadaIframe").style.height = outHeight;
      });
    },
    formatDateTime(newTime) {
      if (!newTime) return '';
      var date = new Date(newTime); //将时间戳转换成日期对象
      console.log(newTime)
      var year = date.getFullYear();  //获取年
      var month = ('0' + (date.getMonth() + 1)).slice(-2); //获取月
      var day = ('0' + date.getDate()).slice(-2);//获取日
      var hours = ('0' + date.getHours()).slice(-2);//获取时
      var minutes = ('0' + date.getMinutes()).slice(-2);//获取分
      var seconds = ('0' + date.getSeconds()).slice(-2);//获取秒
      return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
    },
    backToInfo() {

      this.faDaDaSrc = null
      this.getInfo()
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  border-bottom: 3px solid #1d74e0;
  height: 35px;
  line-height: 27px;
  margin-top: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #666666;
  padding-left: 30px;
}

.form-info {
  margin: 20px 0;
  padding: 10px;
  background: #fff;
  border: 1px solid #e5e5e5;

}

.authoriize ::v-deep .el-button--text {

  color: #DB0E0E;
  padding: 0;
}

.authoriize ::v-deep .have {

  color: #67C23A;
  padding: 0;
}

.form-info ::v-deep .label-cls {
  text-align: right;
  width: 120px;
  display: inline-block;
  margin-right: 30px;
}

.back-btn {
  position: absolute;
  right: 120px;
  top: 73px
}
</style>
